import React from 'react';
import S from './styles';
import Opensea from '../../images/opensea.png'
import etherscan from '../../images/etherscan.png'
import { FaBars, FaTwitter, FaDiscord, FaInstagram } from 'react-icons/fa';
const Navbar = ({ toggle }) => {
  return (
      <>
        <S.Nav>
            <S.NavContainer>
                <S.MobileIcon onClick={toggle}>
                    <FaBars />
                </S.MobileIcon>
                <S.NavMenu>
                    <S.NavItem>
                        <S.NavLinks to="meet the neighbors"  activeClass="active" smooth={true} duration={500}>
                            Meet the Neighbors
                        </S.NavLinks>
                    </S.NavItem>
                    {/* <S.NavItem>
                        <S.NavLinks to="team"  activeClass="active" smooth={true} duration={500}>
                            team
                        </S.NavLinks>
                    </S.NavItem> */}
                </S.NavMenu>
            </S.NavContainer>
            <S.Socials>
                <a target='tab' href="https://twitter.com/NNaughty_"><FaTwitter/></a>
                <a target='tab' href="https://opensea.io/collection/naughtyneighbors"><img className='opensea' src={Opensea} alt="Logo" /></a>
                <a target='tab' href="https://etherscan.io/address/0x34cda4cbf9b6244c3e44166194245441d5ba048f"><img className='opensea' src={etherscan} alt="Logo" /></a>
            </S.Socials>
        </S.Nav>
      </>
  )
};

export default Navbar;
