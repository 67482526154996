import React from 'react';
import S from './styles';
import Opensea from '../../images/opensea.png'
import etherscan from '../../images/etherscan.png'
import { FaTwitter, FaDiscord, FaInstagram } from 'react-icons/fa';
const index = ({isOpen, toggle}) => {
  return (
  <S.SidebarContainer isOpen={isOpen} onClick={toggle}>
      <S.Icon onClick={toggle}>
          <S.CloseIcon/>
      </S.Icon>
      <S.SidebarWrapper>
          <S.SidebarMenu>
              <S.SidebarLink onClick={toggle} to="meet the neighbors" smooth={true} duration={500}>
                meet the neighbors
              </S.SidebarLink>
          </S.SidebarMenu>
          <S.Socials>
          <a target='tab' href="https://twitter.com/NNaughty_"><FaTwitter/></a>
                <a target='tab' href="https://opensea.io/collection/naughtyneighbors"><img className='opensea' src={Opensea} alt="Logo" /></a>
                <a target='tab' href="https://etherscan.io/address/0x34cda4cbf9b6244c3e44166194245441d5ba048f"><img className='opensea' src={etherscan} alt="Logo" /></a>
          </S.Socials>
      </S.SidebarWrapper>
  </S.SidebarContainer>);
};

export default index;
